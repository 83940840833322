import React, { RefObject } from 'react'
import { Message, Segment, Placeholder, Form } from 'semantic-ui-react'

const src = 'https://localhost:3006'

interface Props {
  word: string
  handleInput: (word: string) => Promise<unknown>
  handleBackBtnClick: () => void
}

interface State {
  iframeLoaded: boolean
  unavailable: boolean
}


export default class Dictionary extends React.Component<Props, State> {
  headerRef: RefObject<HTMLHeadingElement>
  iframeRef: RefObject<HTMLIFrameElement>  
  timeout?: number

  constructor(props: any) {
    super(props)

    this.state = {
      iframeLoaded: false,
      unavailable: false
    }

    this.headerRef = React.createRef()
    this.iframeRef = React.createRef()
  }

  componentDidMount = () => {
    this.timeout = window.setTimeout(this.handleIframeTimeout, 2000)

    window.addEventListener('message', this.handleIframeMessage)
  }

  componentWillUnmount = () => {
    window.clearTimeout(this.timeout)

    window.removeEventListener('message', this.handleIframeMessage)
  }

  handleIframeTimeout = () => {
    this.setState({ unavailable: true })
  }
  
  handleIframeLoaded = () => {
    window.clearTimeout(this.timeout)
    this.setState({ iframeLoaded: true })

    console.log("Parent - iframe finished loading")

    const iframe = this.iframeRef.current
    if ( !!iframe && !!iframe.contentWindow ) {
      console.log("Parent - sending hello message to iframe")
      iframe.contentWindow.postMessage('hejhej', src)
    }
  }

  handleIframeMessage = (event: MessageEvent) => {
    // Only handle messages from dictionary iframe
    if ( event.origin !== src ) return;

    if ( event.data === 'DOMContentLoaded' ) {
      this.handleIframeLoaded()
    }
  }

  render() {
    const wrapperStyle = {
      marginBottom: -10,
      backgroundColor: '#edebe9',
      minHeight: 'calc(100vh - 41px)'
    }
    
    const frameStyle = {
      height: 'calc(100vh - 41px - 54px)',
      border: 'none',
      display: this.state.iframeLoaded ? 'block' : 'none'
    }

    const { iframeLoaded, unavailable } = this.state

    return <div style={ wrapperStyle }>
      <Form style={{ padding: '1rem 1rem 0' }}>
        <Form.Input
          placeholder='Skriv in ett ord att slå upp'
          action={{
            primary: true,
            disabled: !iframeLoaded,
            icon: 'search'
          }}
        />
      </Form>
      { (!iframeLoaded && !unavailable) && <div style={{ margin: 16 }}>
        { [1, 2, 3].map(key => <Segment key={ key }>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='short' />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>) }
      </div> }

      { (unavailable && !iframeLoaded) && <Message
        style={{ margin: 16 }}
        error
        header='Ordboken ej tillgänlig'
        content='Servern otillgänglig. Försök igen senare och kontakta Oribi om problemet kvarstår.'
      /> }

      <iframe
        ref={ this.iframeRef }
        src={ src }
        title='LexinOri'
        allowFullScreen={ false }
        width={ window.innerWidth }
        height={ window.innerHeight }
        style={ frameStyle }
      ></iframe>
    </div>
  }
}