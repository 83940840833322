import Translator, { UILanguage } from './api/i18n'
import { OribiApp } from './types'

// If undefined (Rook), display app switcher
// If null, display FatalErrorMessage
export function determineApp(host: string, hash: string = ''): OribiApp|undefined|null {
  let app = null

  // Check host value first
  switch ( host ) {
    // case 'localhost:3000':
    case 'stavarex.officejs.oribisoftware.com':
      app = OribiApp.STAVA_REX
      break
    case 'spellright.officejs.oribisoftware.com':
      app = OribiApp.SPELLRIGHT
      break
    case 'verityspell.officejs.oribisoftware.com':
      app = OribiApp.VERITYSPELL
      break
    case 'stavlet.officejs.oribisoftware.com':
      app = OribiApp.STAVLET
      break
    case 'schreibrex.officejs.oribisoftware.com':
      app = OribiApp.SCHREIBREX
      break
    case 'officejs.oribisoftware.com':
    case 'localhost:3000':
      app = undefined
      break
    default: break
  }

  if ( app !== null ) return app

  // Fall back on hash value
  const hashStr = decodeURIComponent(hash)

  if ( /^#stava\s?rex$/i.test(hashStr) ) app = OribiApp.STAVA_REX
  if ( /^#spellright$/i.test(hashStr) ) app = OribiApp.SPELLRIGHT
  if ( /^#verityspell$/i.test(hashStr) ) app = OribiApp.VERITYSPELL
  if ( /^#stavlet$/i.test(hashStr) ) app = OribiApp.STAVLET
  if ( /^#schreibrex$/i.test(hashStr) ) app = OribiApp.SCHREIBREX

  // If no match (undefined), AppSwitcher should be enabled
  return app
}

interface AppToRun {
  app?: OribiApp|null
  displaySwitcher: boolean
}

export function determineAppToRun(): AppToRun {
  const { host, hash } = window.location
  const cachedApp = localStorage.oribiAppToRun
  const app = cachedApp || determineApp(host, hash)
  const displaySwitcher = !!cachedApp

  return {
    app, displaySwitcher
  }
}

export function getPurchaseLink(app: OribiApp, lang: UILanguage): string {
  switch( app ) {
    case OribiApp.STAVA_REX:
      return 'https://oribisoftware.com/sv/shop/?edd_action=add_to_cart&download_id=175'
    case OribiApp.SPELLRIGHT:
      return lang === 'sv' ?
        'https://oribisoftware.com/sv/shop/?edd_action=add_to_cart&download_id=188' :
        'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=607'
        
    case OribiApp.VERITYSPELL:
      return 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=603'
    case OribiApp.STAVLET:
      return 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=601'
    case OribiApp.SCHREIBREX:
      return 'https://oribisoftware.com/en/shop/?edd_action=add_to_cart&download_id=605'
  }
}

export function getEddIds(app: OribiApp): number[] {
  switch( app ) {
    case OribiApp.STAVA_REX:
      return [175]
    case OribiApp.SPELLRIGHT:
      return [188, 607, 460, 599]
    case OribiApp.VERITYSPELL:
      return [603]
    case OribiApp.STAVLET:
      return [601]
    case OribiApp.SCHREIBREX:
      return [605]
  }
}

export const hasDictionary = (app: OribiApp): boolean => {
  // if ( app === OribiApp.STAVA_REX ) return true
  // if ( app === OribiApp.SPELLRIGHT ) return true
  return false
}

export const getAppSlug = (app: OribiApp) => app.replace(/\s/g, '-').toLowerCase()

export type IconSize = 16 | 32 | 128 | 256
export interface IconUrlOptions {
  size?: IconSize
  transparent?: boolean
}
export const getIconUrl = (app: OribiApp, options?: IconUrlOptions): string => {
  const slug = getAppSlug(app)
  const {
    size = 128,
    transparent = false
  } = options || {}

  return `/icons/${slug}/${transparent ? 'transparent_' : ''}icon_${size}x${size}.png`
}

export const getManualUrl = (app: OribiApp, lang?: UILanguage): string => {
  let url: string
  switch(app) {
    case OribiApp.STAVA_REX:
      url = 'https://oribi.se/guide/stava-rex-365/'
      break
    case OribiApp.SPELLRIGHT:
      url = lang === 'sv' ?
        'https://oribi.se/guide/spellright-365/' :
        'https://oribi.se/en/guide/spellright-docs/'
      break
    case OribiApp.VERITYSPELL:
      url = 'https://oribi.se/en/guide/verityspell-docs/'
      break
    case OribiApp.STAVLET:
      url = 'https://oribi.se/en/guide/stavlet/'
      break
      case OribiApp.SCHREIBREX:
        url = 'https://oribi.se/en/guide/schreibrex/'
        break
    default:
      url = new Translator(lang, app).getMessage('support_link')
      break
  }
  return url
}

// Source refers to the spellchecker language, i.e. the language of the written
// text in need of spellchecking
export type SourceLang = 'en'|'sv'|'da'|'de'
export const getSourceLang = (app: OribiApp): SourceLang => {
  switch(app) {
    case OribiApp.STAVA_REX:
      return 'sv'
    case OribiApp.SCHREIBREX:
      return 'de'
    case OribiApp.STAVLET:
      return 'da'
    default:
      return 'en'
  }
}