import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { requestGraphUser } from './helpers'
import { FatalError, ErrorType } from '../Errors'
import { User } from '../../types'
import { MessageGetter } from '../../api/i18n'
import PropertiesService from '../../api/storage'

interface LogInBtnProps {
  onError: (error: FatalError) => void
  onSuccess: (user: User, accessToken: string) => void
  i18n: MessageGetter
}

const LoginBtn = (props: LogInBtnProps) => {
  const [loading, setLoading] = React.useState(false)

  const handleError = (error: FatalError | null) => {
    setLoading(false)
    if ( error !== null ) props.onError(error)
  }

  return <Button
    disabled={ loading }
    loading={ loading }
    fluid
    primary
    content={ props.i18n('log_in_with_provider', ['Microsoft'] )}
    icon='microsoft'
    onClick={() => {
      setLoading(true)

      const dialogUrl = `${ window.location.origin }/auth-dialog.html`

      Office.context.ui.displayDialogAsync(dialogUrl, {height: 50, width: 30}, (asyncResult) => {
        // Handle dialog creation issues
        if ( asyncResult.status === Office.AsyncResultStatus.Failed ) {
          let error = null
          const { code, message } = asyncResult.error

          if ( code === 12011 ) {
            error = new FatalError(ErrorType.TEAMS_BROWSER, message)
          }
          return handleError(error)
        }

        const dialog = asyncResult.value

        // Handle message from dialog
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (args: any) => {
          // async ({ message }: { message: string }) => {
            dialog.close()
            
            interface AuthResponse {
              status: string
              result: {
                tokenType: string
                accessToken: string
                account: {
                  userName: string
                  name: string
                }
              }
            }
    
            const { message } = args
            const response: AuthResponse = JSON.parse(message)
            if ( response.status !== 'success' ) {
              handleError(new FatalError(ErrorType.LOGIN, JSON.stringify(response, null, 2)))
              return
            }

            const { result } = response
            if ( !result.accessToken ) {
              handleError(new FatalError(ErrorType.LOGIN, JSON.stringify(result, null, 2)))
              return
            }

            const { accessToken } = result
            const graphResponse = await requestGraphUser(accessToken)
            if ( graphResponse.success && graphResponse.user ) {
              const { user } = graphResponse
              props.onSuccess(user, accessToken)
            } else {
              handleError(new FatalError(ErrorType.LOGIN, graphResponse.errorMessage))
            }
          }
        )

        // Handle dialog events
        dialog.addEventHandler(
          Office.EventType.DialogEventReceived,
            ({ error: errorCode }: any) => {
              if ( errorCode === 12006 ) {
                // The dialog box was closed, usually because the user chose the Close button X
                handleError(null)
                return
              }

              let message
              switch ( errorCode ) {
                case 12002:
                  message = 'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.'
                  break
                case 12003:
                  message = 'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.'
                  break
                default:
                  message = undefined
                  break
              }

              handleError(new FatalError(ErrorType.LOGIN, message))
            }
        )
      })
    }}
  />
}

const validateEmail = (email: string) => {
  const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regExp.test(String(email).toLowerCase())
}

interface SimulateLogInProps {
  onSuccess: (user: User) => void
  storage: PropertiesService
  fluid?: boolean
}

export const SimulateLogIn = (props: SimulateLogInProps) => {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')

  useEffect(() => {
    const input = document.getElementById('simulated-email')
    if ( input ) input.focus()
  })

  return <Modal
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
    trigger={<Button fluid={ props.fluid } secondary icon='spy' content='Simulera e-post' />}
  >
    <Modal.Header>
      Simulera e-post
    </Modal.Header>
    <Modal.Content>
      <p>
        Skriv in en e-postadress här om du vill testa licenshanteringen med
        ett annat konto.
      </p>
      <p>
        <strong>Observera att du inte kommer åt användarens inställningar och att
        denna funktion endast är tillgänglig i Rook.</strong>
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Form onSubmit={() => {
        if ( validateEmail(email) ) {
          const user = { email: email, id: email }
          props.storage.setLocal({ simulatedUser: user })
          props.onSuccess(user)
        }
      }}>
        <Form.Input
          id='simulated-email'
          fluid
          placeholder='Ange e-postadress'
          value={ email }
          action={{
            primary: true,
            icon: 'sign in'
          }}
          onChange={(_event, data) => {
            setEmail(data.value)
          }}
        />
      </Form>
    </Modal.Actions>
  </Modal>
}

export default LoginBtn