import React from 'react'
import ReactDOM from 'react-dom'
import { determineAppToRun } from './app'
import AppSwitcher from './Sidebar/AppSwitcher'
import Startup from './Sidebar/Startup/'
import './style.scss'
import { FatalErrorMessage, FatalError, ErrorType } from './Sidebar/Errors'
import PropertiesService from './api/storage'
import { OribiApp } from './types'
// import * as serviceWorker from './serviceWorker'

// Init Office to avoid 10 second timeout
// If more than 10 second elaplses before Office.onReady is called, all fails.
Office.initialize = () => {}

const renderFunction = () => {
  let isLocalStorageAvailable = new PropertiesService(OribiApp.STAVA_REX).isLocalStorageAvailable

  if ( !isLocalStorageAvailable ) {
    return <FatalErrorMessage
      error={ new FatalError(ErrorType.NO_LOCALSTORAGE) }
    /> 
  }

  // Global app variable
  // const { host, hash } = window.location
  // const app = determineApp(host, hash)
  const { app, displaySwitcher } = determineAppToRun()

  if ( app === null ) {
    return <FatalErrorMessage
      error={ new FatalError(ErrorType.APP_IS_NULL) }
    /> 
  }
  
  if ( app === undefined ) {
    return <AppSwitcher />
  } else {
    return <Startup app={ app } enableAppSwitcher={ displaySwitcher } />
  }
}

// Render startup flow
ReactDOM.render(
  renderFunction(),
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
