import React, { Component } from 'react'
import { OfficeInfo, User, OribiApp } from '../types'
import PropertiesService from '../api/storage'
import { Menu, Icon, MenuItemProps } from 'semantic-ui-react'
import SpellChecker from './SpellChecker'
import Dictionary from './Dictionary'
import Settings from './Settings'
import Translator, { MessageGetter, UILanguage } from '../api/i18n'
import { hasDictionary } from '../app'

interface Props {
  app: OribiApp
  officeInfo: OfficeInfo
  user: User
  enableAppSwitcher: boolean
  propertiesService: PropertiesService
  licenseMessage: string
  i18n: Translator
  currentLang: UILanguage
  oribiSpeakExtensionId: string
  speechSynthesisVoice?: SpeechSynthesisVoice
}

interface State {
  activePane: Pane
  dictionaryWord: string
}

// Panes, zero-based index
enum Pane {
  SPELLCHECKER = 'spellchecker',
  DICTIONARY = 'dictionary',
  SETTINGS = 'settings'
}

export default class Main extends Component<Props, State> {
  i18n: MessageGetter

  constructor(props: Props) {
    super(props)

    this.i18n = props.i18n.getMessage

    this.state = {
      activePane: Pane.SPELLCHECKER,
      dictionaryWord: 'abåre'
    }
  }

  handleMenuItemClick = (name: Pane) => {
    if ( this.state.activePane === name ) return;
    this.setState({ activePane: name })
  }

  handleDictionaryBtnClick = (word: string) => {
    this.setState({ dictionaryWord: word }, () => {
      this.setState({ activePane: Pane.DICTIONARY })
    })
  }

  handleDictionaryInput = (word: string) => {
    return new Promise(resolve => {
      this.setState({ dictionaryWord: word }, resolve)
    })
  }

  handleBackBtnClick = () => {
    this.handleMenuItemClick(Pane.SPELLCHECKER)
  }

  render() {
    const { app, officeInfo, user, enableAppSwitcher, propertiesService } = this.props
    const { activePane, dictionaryWord } = this.state
    const { i18n } = this

    const isDictionaryAvailable = hasDictionary(app)

    const menuItems = [
      {
        pane: Pane.SPELLCHECKER,
        icon: 'spell check',
        label: i18n('spellchecker_header')
      },
      {
        pane: Pane.SETTINGS,
        icon: 'settings',
        label: i18n('settings_header')
      }
    ]

    if ( isDictionaryAvailable ) menuItems.splice(1, 0, {
      pane: Pane.DICTIONARY,
      icon: 'book',
      label: i18n('dictionary_header')
    })

    interface TabPanelProps {
      pane: Pane
      grey?: boolean
      branded?: boolean
    }
    const TabPanel: React.FunctionComponent<TabPanelProps> = props => {
      const { pane, children } = props

      const style: { background?: string } = {}
      if ( !!props.grey ) {
        style.background = '#f4f4f4'
      }

      let className = 'tab-view-panel'
      if ( !!props.branded ) className += ' branded'

      return activePane === pane ?
        <div className={ className } style={ style }>
          { children }
        </div> :
        <></>
    }

    if ( this.props.officeInfo.host === null ) return;

    return <div className='tab-view'>
      <TabPanel pane={ Pane.SPELLCHECKER }>
        <SpellChecker
          i18n={ this.props.i18n }
          app={ this.props.app }
          officeInfo={ this.props.officeInfo }
          propertiesService={ this.props.propertiesService }
          oribiSpeakExtensionId={ this.props.oribiSpeakExtensionId }
          handleDictionaryBtnClick={ this.handleDictionaryBtnClick }
          speechSynthesisVoice={ this.props.speechSynthesisVoice }
        />
      </TabPanel>
      { isDictionaryAvailable && <TabPanel pane={ Pane.DICTIONARY }>
        <Dictionary
          word={ dictionaryWord }
          handleInput={ this.handleDictionaryInput }
          handleBackBtnClick={ this.handleBackBtnClick }
        />
      </TabPanel>}
      <TabPanel branded pane={ Pane.SETTINGS }>
        <Settings
          app={ app }
          user={ user }
          officeInfo={ officeInfo }
          propertiesService={ propertiesService }
          enableAppSwitcher={ enableAppSwitcher }
          licenseMessage={ this.props.licenseMessage }
          i18n={ this.props.i18n }
          currentLang={ this.props.currentLang }
        />
      </TabPanel>
      
      <Menu
        fluid
        borderless
        fixed='bottom'
        widths={ menuItems.length as any }
        style={{
          display: 'flex'
        }}
      >
        { menuItems.map(({ pane, icon, label }) => {
          const isActive = pane === activePane

          return <Menu.Item
            key={ pane }
            name={ pane }
            active={ isActive }
            onClick={(_e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: MenuItemProps) => {
              const { name } = data
              if ( name ) this.handleMenuItemClick(name as Pane)
            }}
            style={{
              flexGrow: 1,
              width: 'auto'
            }}
            title={ label }
          >
            { isActive && label }
            { !isActive && <Icon name={ icon as any } /> }
          </Menu.Item>
        }) }
      </Menu>
    </div>
  }
}