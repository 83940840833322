import { User, OribiApp } from '../../types'
import { LicenseType } from '../../api/storage'
import { getEddIds } from '../../app'
import { MessageGetter } from '../../api/i18n'

interface GraphResponse {
  success: boolean
  errorMessage?: string
  user?: User
}

export const requestGraphUser = (accessToken: string): Promise<GraphResponse> => {
  const graphResponse: GraphResponse = {
    success: false
  }
  
  return new Promise(resolve => {
    if ( !accessToken ) return resolve(graphResponse)

    const headers = new Headers()
    const bearer = `Bearer ${ accessToken }`
    headers.append('Authorization', bearer)

    const options = {
      method: 'GET',
      headers: headers
    }
    const graphEndpoint = 'https://graph.microsoft.com/v1.0/me'

    fetch(graphEndpoint, options)
      .then(response => response.json())
      .then(result => {
        if ( result.error ) {
          graphResponse.errorMessage = result.error.message
          resolve(graphResponse)
        } else {
          graphResponse.success = true
          graphResponse.user = {
            email: result.userPrincipalName,
            id: result.id
          }
        }
        resolve(graphResponse)
      })
      .catch((error) => {
        const { message } = error
        graphResponse.errorMessage = message || 'Graph call error'
        resolve(graphResponse)
      })
  })
}

type HabitatAppName = 'OribiSpeak' | 'StavaRex' | 'SpellRight' | 'StavLet' | 'SchreibRex' | 'VeritySpell'
interface HabitatResultApp {
  name: HabitatAppName
  id: 1|2|3|4|5
  licensetype: 'skola'|'unknown'
  licensecolor: 'Grey'|'Black'|'White'
}
interface HabitatResult {
  name: string
  oribi_avtalsid: number
  start_quarter: 0|1|2|3|4
  skolenhetsids: [number, string][]
  apps: HabitatResultApp[]
}
type HabitatResultsList = HabitatResult[]
type DomainChecker =
  (domain: string, app: OribiApp, i18n: MessageGetter, schoolId?: number) =>
    Promise<LicenseInfo>

export interface SchoolId {
  id: number
  name: string
}

interface LicenseInfo {
  type: LicenseType,
  validSchoolIds?: SchoolId[],
  message?: string
}

const getHabitatAppName = (app: OribiApp): HabitatAppName | undefined => {
  switch(app) {
    case OribiApp.STAVA_REX:
      return 'StavaRex'
    case OribiApp.SPELLRIGHT:
      return 'SpellRight'
    case OribiApp.VERITYSPELL:
      return 'VeritySpell'
    case OribiApp.STAVLET:
      return 'StavLet'
    case OribiApp.SCHREIBREX:
      return 'SchreibRex'
    default:
      return undefined
  }
}

const checkDomain: DomainChecker = (domain, app, i18n, schoolId) => new Promise(async resolve => {
  const reservedDomains = /^(?:gmail|hotmail|live|msn|outlook|passport|yahoo)\.(?:com|se)$/
  if ( reservedDomains.test(domain) ) return resolve({ type: LicenseType.UNAUTHORIZED })

  if ( domain === 'oribi.se' ) return resolve({
    type: LicenseType.FREE,
    message: i18n('app_free_for_employees', [app])
  })

  const url = `https://h2.oribi.se/api/h2/v1/avtal/domain/${domain}`
  try {
    const response = await fetch(url)
    if ( !response.ok ) {
      throw new Error(response.statusText)
    }

    const resultList: HabitatResultsList = await response.json()
    if ( !resultList.length ) return resolve({ type: LicenseType.UNAUTHORIZED })

    const licenseInfo: LicenseInfo = {
      type: LicenseType.UNAUTHORIZED
    }

    for ( const habitatResult of resultList ) {
      const { apps } = habitatResult
      const habitatAppName = getHabitatAppName(app)
      if ( !habitatAppName ) continue;

      const licensedApp = apps.find(({ name }) => name === habitatAppName)
      if ( !licensedApp ) continue;

      const { licensecolor } = licensedApp
      if ( licensecolor === 'White' ) {
        licenseInfo.type = LicenseType.DOMAIN
        licenseInfo.message = i18n('license_valid_for_x', [habitatResult.name])
        break
      } else if ( licensecolor === 'Grey' ) {
        const licensedSchool = habitatResult.skolenhetsids.find(([id]) => id === schoolId)
        if ( licensedSchool ) {
          licenseInfo.type = LicenseType.SCHOOL
          licenseInfo.message = i18n('license_valid_for_x', [licensedSchool[1]])
          delete licenseInfo.validSchoolIds
          break
        } else {
          licenseInfo.type = LicenseType.GREYLIST
          delete licenseInfo.message
          
          const { skolenhetsids } = habitatResult
          const schoolIds: SchoolId[] = skolenhetsids.map(([id, name]) => ({ id, name }))
          licenseInfo.validSchoolIds = (licenseInfo.validSchoolIds || []).concat(schoolIds)
        }
      }      
    }

    return resolve(licenseInfo)
  } catch(error) {
    // Run in FREE mode, should anything go wrong (e.g. server unavailable)
    return resolve({
      type: LicenseType.FREE,
      message: i18n('license_server_unavailable', [error.message])
    })
  }
})

type EddActionType = 'check_license'|'activate_license'|'deactivate_license'
interface EDDResult {
  success: boolean
  license: 'valid'|'invalid'|'disabled'|'deactivated'
  item_id: false|number
  item_name: string // Empty string if no product matches item_id
  checksum: string
  error?: 'disabled'|'no_activations_left'|'revoked'|'expired'
  expires?: 'string'
  site_count?: number
  license_limit?: number
  activations_left?: number
  payment_id?: number
  customer_name?: string
  customer_email?: string
}
interface EddCheckerResult {
  success: boolean
  serverAvailable: boolean
  errorMessage?: string
  successMessage?: string
  // expires?: string
}
type EddChecker =
  (key: string, user: User, app: OribiApp, i18n: MessageGetter, action?: EddActionType) =>
    Promise<EddCheckerResult>

interface H2KeyResult {
  customer: string
  appId: number[]
  appName: HabitatAppName[]
  q?: number
  status: 'valid' | 'unknown'
}

export const checkEddKey: EddChecker = (key, user, app, i18n, action = 'check_license') => {
  return new Promise(async (resolve) => {
    const { email } = user
    const result: EddCheckerResult = {
      success: false,
      serverAvailable: true
    }

    // The app may have multiple corresponding IDs in the shop, so let's check
    // all of them.
    const eddIds = getEddIds(app)
    checkloop: for ( const id of eddIds ) {
      try {
        const url = 'https://oribisoftware.com/website/' +
          '?edd_action=' + action +
          '&item_id=' + id +
          '&license=' + encodeURIComponent( key ) +
          '&url=' + encodeURIComponent( email )
          
        const eddResult: EDDResult = await fetch(url).then(resp => resp.json())
        if ( eddResult.license === 'valid' ) {
          result.success = true
          if ( eddResult.expires ) {
            result.successMessage = i18n('license_key_valid_until_x', [eddResult.expires])
          }
          break // break checkloop
        } else if ( eddResult.license === 'disabled' ) {
          result.errorMessage = i18n('license_key_disabled')
          break // break checkloop
        } else if ( eddResult.license === 'deactivated' ) { // && eddResult.success ?
          result.success = true
          // result.successMessage = 'Nyckeln kunde avaktiveras utan problem.'
        } else {
          switch ( eddResult.error ) {
            case 'no_activations_left':
              result.errorMessage = i18n('license_key_no_activations_left')
              break checkloop
            case 'disabled':
            case 'revoked':
            case 'expired':
              result.errorMessage = i18n('license_key_disabled')
              break checkloop
            default:
              result.errorMessage = i18n('license_key_invalid')
              break // break switch statement, not checkloop
          }
          continue // continue checkloop
        }
      } catch(error) {
        // No response
        result.serverAvailable = false
        result.errorMessage = i18n('license_server_unavailable', [error.message])
        break // break checkloop
      }
    }

    return resolve(result)
  })
}

// If license key starts with a capital 'H', check with Habitat instead of Oribi
// Web Store.
export const checkHabitatKey: EddChecker = (key, user, app, i18n)  => {
  return new Promise(resolve => {
    const checkResult: EddCheckerResult = {
      success: false,
      serverAvailable: true
    }

    fetch('https://h2.oribi.se/api/v1/license/' + key)
      .then(async response => {
        let result: Promise<H2KeyResult>

        if ( !response.ok ) {
          result = Promise.resolve({
            status: 'unknown',
            customer: '',
            appId: [],
            appName: []
          })
        } else {
          result = response.json()
        }

        return result
      })
      .then(result => {
        if ( result.status !== 'valid' ) {
          checkResult.errorMessage = i18n('license_key_invalid')
          return checkResult
        }
        
        const appName = getHabitatAppName(app)
        if ( !!appName && result.appName.includes(appName) ) {
          checkResult.success = true
          checkResult.successMessage = i18n('license_valid_for_x', [result.customer])
        } else {
          checkResult.errorMessage = i18n('license_key_invalid')
        }

        return checkResult
      })
      .catch(({ message }) => {
        checkResult.success = false
        checkResult.errorMessage = message

        return checkResult
      })
      .then(resolve)
  })
}

export interface LicenseCheckerProps {
  user: User
  licenseType: LicenseType
  app: OribiApp
  trialStart?: string
  schoolId?: number
  handleStatusChange?: (statusText: string) => void
  handleTrialExpired?: () => void
  licenseKey?: string
  i18n: MessageGetter
}
type LicenseChecker = (props: LicenseCheckerProps) => Promise<LicenseInfo>

export const checkUserLicense: LicenseChecker = (props) => {
  const {user, app, handleStatusChange, trialStart, schoolId, handleTrialExpired, i18n} = props
  let { licenseType } = props
  let licenseInfo: LicenseInfo

  const getTrialInfo = (trialStart: string, limit = 30) => {
    const days = 24 * 60 * 60 * 1000 // 1 day in ms
    const trialLimit = limit * days
    const trialStartTime = new Date(trialStart).getTime()
    const trialEndTime = trialStartTime + trialLimit
    const timeLeft = trialEndTime - Date.now()

    return {
      expired: timeLeft <= 0,
      daysLeft: Math.round(timeLeft / days)
    }
  }
  
  // Call handleTrialExpired no matter which license type is checked
  if ( !!trialStart && !!handleTrialExpired ) {
    const { expired } = getTrialInfo(trialStart)
    if ( expired ) handleTrialExpired()
  }

  return new Promise(async resolve => {
    if ( !user.email ) return resolve({ type: LicenseType.FREE })

    const domain = user.email.split('@')[1]

    if ( !!handleStatusChange ) handleStatusChange(i18n('status_validating_license'))
    
    switch ( licenseType ) {
      // User never authorized to use app
      case LicenseType.UNKNOWN:
      case LicenseType.FREE:
      case LicenseType.UNAUTHORIZED:
      case LicenseType.GREYLIST:
      case LicenseType.SCHOOL:
      case LicenseType.DOMAIN:
        licenseInfo = await checkDomain(domain, app, i18n, schoolId)
        resolve(licenseInfo)
        break
      // User has started trial
      case LicenseType.TRIAL:
        if ( !trialStart ) return resolve({
          type: LicenseType.TRIAL,
          message: i18n('trial_active')
        })
        
        const { expired, daysLeft } = getTrialInfo(trialStart)
        if ( !expired ) {
          let daysLeftString = i18n('in_x_days', [daysLeft.toString()])

          if ( daysLeft === 0 ) {
            daysLeftString = i18n('today')
          } else if ( daysLeft === 1 ) {
            daysLeftString = i18n('tomorrow')
          } else if ( daysLeft === 2 ) {
            daysLeftString = i18n('day_after_tomorrow')
          }
          
          resolve({
            type: LicenseType.TRIAL,
            message: i18n('trial_end_x', [daysLeftString])
          })
        } else {
          licenseInfo = await checkDomain(domain, app, i18n)
          resolve(licenseInfo)
        }
        break
      case LicenseType.LICENSE_KEY:
        // Re-check license key with EDD
        const { licenseKey } = props

        if ( !licenseKey ) {
          licenseInfo = await checkDomain(domain, app, i18n)
          resolve(licenseInfo)
        } else {
          const eddCheckResult = licenseKey.startsWith('H') ?
            await checkHabitatKey(licenseKey, user, app, i18n) :
            await checkEddKey(licenseKey, user, app, i18n)
          
          const { success, successMessage, serverAvailable, errorMessage } = eddCheckResult
          if ( success ) {
            resolve({
              type: LicenseType.LICENSE_KEY,
              message: successMessage
            })
          } else if ( !serverAvailable ) {
            resolve({
              type: LicenseType.FREE,
              message: errorMessage
            })
          } else {
            licenseInfo = await checkDomain(domain, app, i18n)
            resolve(licenseInfo)
          }
        }
        break
      default:
        resolve({ type: licenseType })
        break
    }
  })
}