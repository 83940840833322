import React from 'react'
import { MessageGetter } from '../../api/i18n'
import { SpellcheckSuggestion } from '.'
import { List, Container, Button } from 'semantic-ui-react'
import { OribiApp } from '../../types'
import EmptyDocMessage from './EmptyDocMessage'
import { TTSProvider } from '../TTS/api'

interface SuggestionListItemProps {
  translation: string
  word: string
  sentence: string
  index: number
  activeIndex?: number
  i18n: MessageGetter
  original: string
  onClick: () => void
  onDoubleClick: () => void
  handleContextMenuChange: () => void
  handleContextMenuSpeak: () => void
  handleContextMenuDictionary: () => void
  speaking?: boolean
  ttsProvider: TTSProvider | null
  isDictionaryAvailable?: boolean
  handleWordClick: (word: SelectableWord) => void
}

interface SelectableWord {
  text: string
  i: number
}

const SuggestionListItem = (props: SuggestionListItemProps) => {
  const { translation, index, activeIndex, original, word, sentence, ttsProvider, speaking } = props
  const isActive = index === activeIndex
  const [selectedWord, setSelectedWord] = React.useState(word)

  const dropdownReplaceText = original === word ?
    props.i18n('command_keep', [word]) :
    props.i18n('suggestion_change_hint', [original, word])

  const isOribiSpeakInstalled = ttsProvider === TTSProvider.ORIBI_SPEAK
  // const hotkeyCommand = index < 9 ? `alt+${index + 1}` : undefined // Alt ⌥

  const HeaderContent = () => {
    const Selectable = (word: SelectableWord) => {
      const { text } = word
      const isSelected = selectedWord === text
      let className = 'word'
      if ( isSelected ) className += ' selected'

      const handleClick = () => {
        if ( !isSelected ) {
          setSelectedWord(text)
          props.handleWordClick(word)
        }
      }
      
      return <span className={ className } onClick={ handleClick }>
        { text }
      </span>
    }
    
    if ( !sentence ) return <Selectable i={ 0 } text={ word } />

    interface Part {
      text: string
      selectable: boolean
      selectableI: number
    }
    const parts = sentence
      .split(/(<[^<>]+>)/g)
      .reduce((pts: Part[], text, i) => {
        const part = {
          text: text.replace(/^<([^<>]+)>$/, '$1'),
          selectable: /^<[^<>]+>$/.test(text),
          selectableI: pts.filter(({ selectable }) => selectable).length
        }

        return pts.concat(part)
      }, [])

    return <>
      { parts.map(({ text, selectable, selectableI }, i) => {
        return selectable ?
          <Selectable i={ selectableI } key={i} text={ text } /> :
          <span key={i}>{ text }</span>
      })}
    </>
  }

  const handleLiClick = () => {
    if ( isActive ) return;
    props.onClick()
  }

  return <List.Item
    title={ dropdownReplaceText }
    active={ isActive }
    onClick={ handleLiClick }
    key={ index }
    className='suggestion'
  >
    <List.Content>
      <List.Header
        style={{
          fontSize: '1.28571429em',
          lineHeight: '1.3'
        }}
      >
        <div className='suggestion-text' onDoubleClick={ props.onDoubleClick }>
          <HeaderContent />
        </div>
      { ttsProvider !== null &&
        <div className='action'>
          <Button
            basic={ !speaking }
            disabled={ speaking }
            color={ isOribiSpeakInstalled ? 'green' : 'grey' }
            style={{ padding: 4 }}
            icon='volume up'
            title={ props.i18n(isOribiSpeakInstalled ?
              'suggestion_listen_with_os' :
              'suggestion_listen')
            }
            onClick={ props.handleContextMenuSpeak }
          />
        </div>
      }
      </List.Header>
      { translation && 
      <List.Description style={{ marginTop: '.5em'}}>
        { translation }
      </List.Description> }
    </List.Content>
  </List.Item>
}

interface Props {
  i18n: MessageGetter
  suggestions: SpellcheckSuggestion[]
  activeSuggestion?: SpellcheckSuggestion
  loading?: boolean
  visibleSuggestions: number
  original: string
  handleClick: (suggestion: SpellcheckSuggestion) => void
  handleDblClick: (suggestion: SpellcheckSuggestion) => void
  handleContextMenuChange: (suggestion: SpellcheckSuggestion) => void
  handleContextMenuSpeak: (suggestion: SpellcheckSuggestion) => void
  handleContextMenuDictionary: (word: string) => void
  app: OribiApp
  speakingSuggestion?: SpellcheckSuggestion
  displayEmptyDocMessage: boolean
  ttsProvider: TTSProvider | null
  handleWordClick: (word: SelectableWord) => void
}

const Suggestions = (props: Props) => {
  const {
    suggestions,
    activeSuggestion,
    visibleSuggestions,
    original,
    i18n,
    displayEmptyDocMessage
  } = props
  const activeIndex = activeSuggestion ? activeSuggestion.index : undefined

  return <Container>
  { displayEmptyDocMessage && 
    <EmptyDocMessage
      i18n={ props.i18n }
      app= { props.app }
    />
  }
    <List selection relaxed className='suggestions'>
    { suggestions.slice(0, visibleSuggestions).map((suggestion, index) => {
      const { translation, word, sentence } = suggestion

      return <SuggestionListItem
          key={ index }
          translation={ translation }
          word={ word }
          sentence={ sentence }
          activeIndex={ activeIndex }
          index={ index }
          original={ original }
          onClick={ () => props.handleClick(suggestion) } 
          onDoubleClick={ () => props.handleDblClick(suggestion) }
          handleContextMenuChange={ () => props.handleContextMenuChange(suggestion) }
          i18n={ i18n }
          ttsProvider={ props.ttsProvider }
          handleContextMenuSpeak={ () => props.handleContextMenuSpeak(suggestion) }
          handleContextMenuDictionary={ () => props.handleContextMenuDictionary(suggestion.word) }
          speaking={ props.speakingSuggestion === suggestion }
          handleWordClick={(word) => {
            suggestion.word = word.text
            props.handleWordClick(word)
          }}
        />
    }) }
    </List>
  </Container>
}

export default Suggestions